
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "buildId": "62256355785f8d1cd9b59fccc07263c10ecc49ab",
  "nuxt": {
    "buildId": "029a9e11-3ada-499d-a0a0-493ec3abfd65"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)
